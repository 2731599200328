<template>
  <div>
    <keep-alive include="pilot">
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
export default {
  name: "index",
  components: {},
};
</script>
<style lang="scss" scoped>
@import "../../css/sass_files";
</style>
